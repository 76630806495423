import Async from "@components/Async";
import Icon from "@components/Icon";
import { useCountdown } from "@hooks";
import { ContestStatus, IContest } from "@services/contest/types";
import { isPast } from "date-fns";
import Link from 'next/link';

interface ContestCardProps {
  contest: IContest & { nContestants: number; nVoters: number; nVotes: number; };
  enableFilter?: boolean;
}

const ContestCard = function ({ contest, enableFilter = false }: ContestCardProps) {
  const countdown$ = useCountdown(
    isPast(new Date(contest.votingStarts))
      ? contest.votingEnds
      : contest.votingStarts,
  );

  return (
    <div className={`${enableFilter ? 'col-md-4' : 'col-md-4 col-xs-12 col-sm-12'} mb-5`}>
      <Link href={`/contests/${contest.slug}`}>
        <a style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="contest-card">
            <div className="img-content">
              <svg viewBox="0 0 525 400" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <mask id={`${contest.slug}-mask0`} style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="-5" y="0" width="530" height="400">
                  <path d="M525 29.1644V400C524.238 397.166 523.847 394.241 522.754 391.478C520.174 385.184 515.779 379.805 510.13 376.031C504.482 372.257 497.837 370.259 491.049 370.293C337.91 370.427 184.771 370.454 31.6326 370.373C30.4495 370.373 29.2763 370.373 28.1031 370.373C23.7085 370.363 19.3597 369.478 15.3091 367.77C11.2586 366.061 7.58701 363.563 4.50764 360.421C1.42827 357.278 -0.997557 353.554 -2.629 349.464C-4.26043 345.374 -5.06497 341 -4.9959 336.596C-4.9959 235.63 -4.9959 134.653 -4.9959 33.6666C-5.09026 26.4241 -2.81632 19.3497 1.47849 13.5244C5.77329 7.69911 11.8528 3.44321 18.7881 1.40696C20.5328 0.874322 22.3276 0.472338 24.1024 0H495.892C496.674 0.211045 497.436 0.452234 498.228 0.623079C502.668 1.51963 506.882 3.30647 510.616 5.87624C514.35 8.44601 517.527 11.7457 519.956 15.5771C522.714 19.7377 523.827 24.4611 525 29.1644Z" fill="black" />
                </mask>
                <g mask={`url(#${contest.slug}-mask0)`}>
                  <rect y="-199" width="525" height="798" fill={`url(#${contest.slug}-pattern0)`} />
                </g>
                <defs>
                  <pattern id={`${contest.slug}-pattern0`} patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref={`#${contest.slug}-image`} transform="translate(-0.042944) scale(0.000608 0.0004)" />
                  </pattern>
                  <image id={`${contest.slug}-image`} width="1786" height="2500" xlinkHref={contest.image} />
                </defs>
              </svg>
              <div className="desc d-flex justify-content-between align-items-center">
                <span className="rate rounded-pill">
                  {contest.votingFee.value === '0' ? 'FREE ' : `${contest.votingFee.currency} ${contest.votingFee.value}/`}vote
                </span>

                {contest.status === ContestStatus.Published &&
                  <div className="countdown-timer rounded-pill">
                    {isPast(new Date(contest.votingEnds)) ?
                      <>
                        <span className="rounded-pill bg-danger text-uppercase text-light px-3 py-1">Voting closed</span>
                      </> :
                      <>
                        <span className={`prefix text-uppercase rounded-pill bg-${isPast(new Date(contest.votingStarts)) ? 'danger' : 'success'}`}>
                          {isPast(new Date(contest.votingStarts)) ? 'ends' : 'starts'} in
                        </span>
                        <span className="time rounded-pill">
                          <Async>{countdown$}</Async>
                        </span>
                      </>
                    }
                  </div>
                }

                {contest.status === ContestStatus.Draft &&
                  <>
                    <span className="fs-label rounded-pill bg-leader-orange text-uppercase text-light px-2">
                      Draft
                    </span>
                  </>
                }
              </div>
            </div>
            <div className="text-content mx-2">
              <h4 className="fw-bold text-capitalize fs-card-title">{contest.title}</h4>
              <div className="count py-1 d-flex justify-content-between align-items-center">
                <span>
                  <Icon name="voter"></Icon>
                  {contest.nVoters}
                </span>
                <span>
                  <Icon name="vote"></Icon>
                  {contest.nVotes}
                </span>
                <span>
                  <Icon name="people"></Icon>
                  {contest.nContestants}
                </span>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div >
  );
};

export default ContestCard;
